.SweeTreats .lander {
    padding: 20px 0;
    text-align: center;
}

.SweeTreats .lander h1 {
    font-family: "Open Sans", sans-serif;
    font-weight: 600;
}

.SweeTreats .lander p {
    color: #999;
}

.SweeTreats .jumbotron {
    background-image: url("/images/store/candy_letters.png");
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    background-color: transparent;
}

