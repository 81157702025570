.fblock .social .facebook {
    display: inline-block;
}

.fblock .social .instagram {
    display: inline-block;
}

.fblock .social .twitter {
    display: inline-block;
}

.fblock .social .pinterest {
    display: inline-block;
}

.fblock .privacy {
    color: white;
}

.fblock .jumbotron {
    background-color: transparent;
}

.fblock .copyright {
    color: white;
    background-color: #f1688d;
    padding-top: @padding-small-horizontal / 2;
    padding-bottom: @padding-small-horizontal;
}
