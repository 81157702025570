.Home .lander {
    padding: 20px 0;
    text-align: left;
}

.Home .lander .announcement {
    display: table;
}

.Home .lander .announcement .image {
    display: inline-block;
    width: 50%;
    display: table-cell;
    vertical-align: middle;
}

.Home .lander .announcement .proclaim {
    display: inline-block;
    display: table-cell;
    vertical-align: middle;
}

.Home .lander .announcement .proclaim h1 {
    font-family: "Open Sans", sans-serif;
    font-weight: 600;
}

.Home .lander p {
    color: #999;
}

.Home .lander .footer {
    padding: 20px 0;
    text-align: center;
  }

