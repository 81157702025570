.WhatWeBelieve .lander {
  padding: 20px 0;
  text-align: center;
}

.WhatWeBelieve .lander .announcement {
  display: table;
}

.WhatWeBelieve .lander .announcement .image {
  display: inline-block;
  width: 40%;
  display: table-cell;
  vertical-align: middle;
}

.WhatWeBelieve .lander .announcement .beliefs {
  display: inline-block;
  display: table-cell;
  vertical-align: middle;
}

.WhatWeBelieve .lander p {
  color: #999;
}
