.AboutUs .lander {
  padding: 20px 0;
  text-align: left;
}

.AboutUs .lander .footer {
  padding: 20px 0;
  text-align: center;
}

.AboutUs .lander .announcement {
  display: table;
}

.AboutUs .lander .announcement .image {
  display: inline-block;
  width: 40%;
  display: table-cell;
  vertical-align: middle;
}

.AboutUs .lander .announcement .allabout {
  display: inline-block;
  display: table-cell;
  text-align: middle;
  vertical-align: top;
}

.AboutUs .lander .announcement .allabout h1 {
  font-family: "Open Sans", sans-serif;
  font-weight: 600;
}


